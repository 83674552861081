// import PropTypes from 'prop-types';
// import * as Yup from 'yup';
// import { useEffect, useMemo, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// // @mui
// import LoadingButton from '@mui/lab/LoadingButton';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Unstable_Grid2';
// import Typography from '@mui/material/Typography';
// // assets
// import { countries } from 'src/assets/data';
// // components
// import Iconify from 'src/components/iconify';
// import { useSnackbar } from 'src/components/snackbar';
// import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
// import { Button } from '@mui/material';
// import UserOTPForm from './user-otp-form';
// import axiosInstance from '../../utils/axios';

// // ----------------------------------------------------------------------
// export default function UserNewEditForm({ onNextStep, onBackStep }) {
//   const { enqueueSnackbar } = useSnackbar();

//   const [OPTForm] = useState({ toggle: false, phone_number: '', country_code: '' });
//   const NewUserSchema = Yup.object().shape({
//     // name: Yup.string(),
//     // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
//     phoneNumber: Yup.string().required('Phone number is required'),
//     country: Yup.object().required('Country is required'),
//     isVerified: Yup.boolean(),
//   });

//   const defaultValues = useMemo(
//     () => ({
//       name: '',
//       email: '',
//       country: '',
//       phoneNumber: '',
//       isVerified: false,
//     }),
//     []
//   );
//   // /auth/client-users
//   const methods = useForm({
//     resolver: yupResolver(NewUserSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     watch,
//     formState: { isSubmitting },
//   } = methods;

//   const onSubmit = handleSubmit(async (data) => {
//     try {
//       // await new Promise((resolve) => setTimeout(resolve, 500));
//       // reset();
//       const schema = {
//         phone_number: data.phoneNumber,
//         country_code: data.country?.phone,
//       };
//       const response = await axiosInstance.post('/api/v2/auth/client-users/register/', schema);
//       if (response?.data?.success) {
//         enqueueSnackbar('Logged in successfully!');
//         // onNextStep();
//         // onReset();
//         console.info('DATA', response?.data);
//         // enqueueSnackbar('OTP Verified successfully!');
//         localStorage.setItem(
//           'u',
//           JSON.stringify({
//             ...response?.data?.data,
//             phone_number: data.phoneNumber,
//             country_code: data.country?.phone,
//           })
//         );
//         onNextStep();
//         console.info('DATA', response?.data);
//         // setOPTForm((d) => ({ ...d, toggle: true, country_code: data.country?.phone, phone_number: data.phoneNumber }));
//       }
//       // router.push(paths.dashboard.user.list);
//     } catch (error) {
//       console.error(error);
//     }
//   });

//   const [currentUser, setCurrentUser] = useState(null);
//   useEffect(() => {
//     const loggedInUser = JSON.parse(localStorage.getItem('u'));
//     console.log('loggedInUser', loggedInUser);
//     if (loggedInUser) setCurrentUser(loggedInUser);
//   }, []);

//   const onClickChange = () => {
//     localStorage.removeItem('u');
//     setCurrentUser(null);
//   };
//   console.log(watch());

//   if (currentUser?.user_id && currentUser) {
//     return (
//       <Grid container spacing={3}>
//         <Grid xs={12} md={8} mb={4}>
//           <Card sx={{ p: 3 }}>
//             <Box rowGap={3} columnGap={2} display="flex">
//               <Typography>
//                 {` You are already logged in with ${currentUser?.country_code} ${currentUser?.phone_number}`}
//               </Typography>
//             </Box>

           
//           </Card>
//           <Stack display="flex" flexDirection="row-reverse" alignItems="flex-end" spacing={3} sx={{ mt: 3 }}>
//               <LoadingButton
//                 onClick={onNextStep}
//                 type="submit"
//                 variant="contained"
//                 disabled={OPTForm?.toggle}
//                 loading={isSubmitting}
//                 endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
//                 color="primary"
//               >
//                 Next
//               </LoadingButton>
//               <LoadingButton onClick={onClickChange} variant="text" 
//                 endIcon={<Iconify icon="solar:pen-bold" />}
              
//               disabled={OPTForm?.toggle} loading={isSubmitting}>
//                 Change
//               </LoadingButton>
//               <Button
//                 size="small"
//                 color="inherit"
//                 onClick={onBackStep}
//                 sx={{mr:'auto'}}
//                 startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
//               >
//                 Back
//               </Button>
//             </Stack>
//         </Grid>
//       </Grid>
//     );
//   }
//   return (
//     <>
//       <FormProvider methods={methods} onSubmit={onSubmit}>
//         <Grid container spacing={3}>
//           <Grid xs={12} md={12}>
//             <Card sx={{ p: 3 }}>
//               <Box
//                 rowGap={3}
//                 columnGap={2}
//                 display="grid"
//                 gridTemplateColumns={{
//                   xs: 'repeat(1, 1fr)',
//                   sm: 'repeat(2, 1fr)',
//                 }}
//               >
//                 {/* <RHFTextField name="name" label="Full Name" /> */}
//                 {/* <RHFTextField name="email" label="Email Address" /> */}
//                 <RHFAutocomplete
//                   name="country"
//                   label="Country Code"
//                   options={countries.map((country) => country)}
//                   getOptionLabel={(o) => (o ? `${o.label} ${o.phone}` : '')}
//                   isOptionEqualToValue={(option, value) => option === value}
//                   renderOption={(props, option) => {
//                     const { code, label, phone } = countries.filter((country) => country === option)[0];

//                     if (!label) {
//                       return null;
//                     }

//                     return (
//                       <li {...props} key={label}>
//                         <Iconify key={label} icon={`circle-flags:${code.toLowerCase()}`} width={28} sx={{ mr: 1 }} />
//                         {label} ({code}) {phone}
//                       </li>
//                     );
//                   }}
//                 />
//                 <RHFTextField name="phoneNumber" label="Phone Number" />
//               </Box>

//               <Stack alignItems="flex-end" sx={{ mt: 3 }}>
//                 <LoadingButton type="submit" color='primary' variant="contained" disabled={OPTForm?.toggle} loading={isSubmitting}>
//                   Login
//                 </LoadingButton>
//               </Stack>
//             </Card>
//           </Grid>
//         </Grid>
//       </FormProvider>

//       {OPTForm?.toggle && <UserOTPForm userDetails={OPTForm} onNextStep={onNextStep} />}
//     </>
//   );
// }

// UserNewEditForm.propTypes = {
//   currentUser: PropTypes.object,
//   onNextStep: PropTypes.func,
//   onBackStep: PropTypes.func,
// };

// updated code 
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import { Button } from '@mui/material';
import UserOTPForm from './user-otp-form';
import axiosInstance from '../../utils/axios';

// ----------------------------------------------------------------------

export default function UserNewEditForm({ onNextStep, onBackStep }) {
  const { enqueueSnackbar } = useSnackbar();

  const [OPTForm] = useState({ toggle: false, phone_number: '', country_code: '' });
  const [currentUser, setCurrentUser] = useState(null);

  const NewUserSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Phone number is required'),
    country: Yup.object().required('Country is required'),
    isVerified: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      email: '',
      country: '',
      phoneNumber: '',
      isVerified: false,
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem('u'));
    if (loggedInUser) setCurrentUser(loggedInUser);
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const schema = {
        phone_number: data.phoneNumber,
        country_code: data.country?.phone,
      };
      const response = await axiosInstance.post('/api/v2/auth/client-users/register/', schema);
      if (response?.data?.success) {
        enqueueSnackbar('Logged in successfully!');
        localStorage.setItem(
          'u',
          JSON.stringify({
            ...response?.data?.data,
            phone_number: data.phoneNumber || '',
            country_code: data.country?.phone || '',
          })
        );
        setCurrentUser(response?.data?.data); 
        window.location.reload(); 
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Login failed. Please try again.', { variant: 'error' });
    }
  });

  const onClickChange = () => {
    localStorage.removeItem('u');
    setCurrentUser(null);
  };

  if (currentUser?.user_id) {
    return (
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Typography>
              {`You are already logged in with ${
                currentUser?.country_code ? currentUser.country_code : 'N/A'
              } ${currentUser?.phone_number ? currentUser.phone_number : 'N/A'}`}
            </Typography>
          </Card>
          <Stack direction="row-reverse" spacing={2} sx={{ mt: 3 }}>
            <LoadingButton
              onClick={onClickChange}
              variant="text"
              endIcon={<Iconify icon="solar:pen-bold" />}
              disabled={isSubmitting}
            >
              Change
            </LoadingButton>
              <div style={{ flexGrow: 1 }}>
              <Button
                onClick={onBackStep}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
              >
                Back
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFAutocomplete
                  name="country"
                  label="Country Code"
                  options={countries.map((country) => country)}
                  getOptionLabel={(o) => (o ? `${o.label} ${o.phone}` : '')}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => {
                    const { code, label, phone } = countries.filter((country) => country === option)[0];
                    return (
                      <li {...props} key={label}>
                        <Iconify icon={`circle-flags:${code.toLowerCase()}`} width={28} sx={{ mr: 1 }} />
                        {label} ({code}) {phone}
                      </li>
                    );
                  }}
                />
                <RHFTextField name="phoneNumber" label="Phone Number" />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={OPTForm?.toggle}
                  loading={isSubmitting}
                >
                  Login
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>

      {OPTForm?.toggle && <UserOTPForm userDetails={OPTForm} onNextStep={onNextStep} />}
    </>
  );
}

UserNewEditForm.propTypes = {
  onNextStep: PropTypes.func,
  onBackStep: PropTypes.func,
};