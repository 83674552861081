// Old code

// import { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import * as Yup from 'yup';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// // @mui
// import Button from '@mui/material/Button';
// import Grid from '@mui/material/Unstable_Grid2';
// import LoadingButton from '@mui/lab/LoadingButton';
// // components
// import Iconify from 'src/components/iconify';
// import FormProvider from 'src/components/hook-form';
// //
// import _ from 'lodash';
// import { Card, CardHeader } from '@mui/material';
// import { useSearchParams } from 'react-router-dom';
// import CheckoutSummary from './checkout-summary';
// // import CheckoutDelivery from './checkout-delivery';
// import CheckoutBillingInfo from './checkout-billing-info';
// import CheckoutPaymentMethods from './checkout-payment-methods';
// import axiosInstance from '../../../utils/axios';

// // ----------------------------------------------------------------------

// // const DELIVERY_OPTIONS = [
// //   {
// //     value: 0,
// //     label: 'Free',
// //     description: '5-7 Days delivery',
// //   },
// //   {
// //     value: 10,
// //     label: 'Standard',
// //     description: '3-5 Days delivery',
// //   },
// //   {
// //     value: 20,
// //     label: 'Express',
// //     description: '2-3 Days delivery',
// //   },
// // ];

// const PAYMENT_OPTIONS = [
//   {
//     value: 'paypal',
//     label: 'Pay with Paypal',
//     description: 'You will be redirected to PayPal website to complete your purchase securely.',
//   },
//   {
//     value: 'card',
//     label: 'Credit / Debit Card',
//     description: 'You will be redirected to payment page.',
//   },
//   {
//     value: 'cash',
//     label: 'Cash',
//     description: 'Pay with cash when your order is delivered.',
//   },
// ];

// const CARDS_OPTIONS = [
//   { value: 'ViSa1', label: '**** **** **** 1212 - Jimmy Holland' },
//   { value: 'ViSa2', label: '**** **** **** 2424 - Shawn Stokes' },
//   { value: 'MasterCard', label: '**** **** **** 4545 - Cole Armstrong' },
// ];

// export default function CheckoutPayment({ checkout, onReset, onNextStep, onBackStep, onGotoStep, onApplyShipping }) {
//   const { total, discount, subTotal, shipping, billing } = checkout;
//   const [paymentURL] = useState('');

//   const PaymentSchema = Yup.object().shape({
//     payment: Yup.string().required('Payment is required!'),
//   });

//   const defaultValues = {
//     delivery: shipping,
//     payment: '',
//   };

//   const [searchParams] = useSearchParams();

//   useEffect(() => {
//     const payment_success = searchParams.get('payment_success');

//     if (payment_success) {
//       onNextStep();
//       onReset();
//     }
//   }, [onNextStep, onReset, searchParams]);

//   const methods = useForm({
//     resolver: yupResolver(PaymentSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     watch,
//     formState: { isSubmitting },
//   } = methods;

//   const { payment } = watch();

//   const onSubmit = handleSubmit(async (data) => {
//     console.log('data: ', data);
//     try {
//       // eslint-disable-next-line no-debugger
//       const invoicesGrouped = _.groupBy(checkout.cart, 'brand_id');
//       const invoices = [];
//       const user = JSON.parse(localStorage.getItem('u') || '{}');

//       if (!user && user?.user_id) {
//         alert('Please login to continue');
//         return;
//       }

//       // eslint-disable-next-line no-restricted-syntax
//       for (const key in invoicesGrouped) {
//         if (Object.hasOwnProperty.call(invoicesGrouped, key)) {
//           const itemArray = invoicesGrouped[key];
//           const price = itemArray?.map((item) => item.price).reduce((prev, next) => prev + next);
//           const locationInvoice = {
//             brand_id: key,
//             customer_id: itemArray[0].customer_id,
//             location_id: itemArray[0].location_id,
//             discount_id: null, // this will be when discount applied
//             info_address: checkout.billing.fullAddress,
//             info_email: checkout.billing.email,
//             info_name: checkout.billing.name,
//             info_phone: `${user.country_code}${user.phone_number}`,
//             is_paid: 0,
//             items_number: itemArray?.length,
//             order_type: 'Online',
//             payable: price,
//             payment_method: [
//               {
//                 [data.payment]: price,
//               },
//             ],
//             items: itemArray.map((i) => ({ ...i, product_id: i.id, qty: i.quantity })),
//           };

//           invoices.push(locationInvoice);
//         }
//       }

//       const schema = {
//         total: checkout.subTotal,
//         vat: 0,
//         subtotal: checkout.subTotal,
//         note: 'Hey',
//         discount_amount: checkout.discount,
//         discount_pct: 0,
//         customer_id: checkout.cart[0].customer_id,
//         client_user_id: user?.user_id,
//         user_address: checkout.billing.fullAddress,
//         user_phone: `${user.country_code}${user.phone_number}`,
//         user_email: checkout.billing.email,
//         payment_method: data.payment,
//         invoices,
//       };
//       if (data.payment === 'card') {
//         schema.redirectURL = window.location.href;
//       }

//       const response = await axiosInstance.post('/api/v2/multi-location-orders/sync-order', schema);
//       localStorage.setItem('rurl', `/dashboard/product${window.location.search}`);

//       if (data.payment === 'card') {
//         if (response?.data?.data?.payment_info?.paymentUrl) {
//           window.open(`${response?.data?.data?.payment_info?.paymentUrl}`, '_top');
//         }
//       } else if (response?.data?.status) {
//         if (data?.payment === 'cash') {
//           onNextStep();
//         }
//         onReset();
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   });

//   return (
//     <FormProvider methods={methods} onSubmit={onSubmit}>
//       <Grid container spacing={3}>
//         <Grid xs={12} md={8}>

//           {/* <CheckoutDelivery onApplyShipping={onApplyShipping} options={DELIVERY_OPTIONS} /> */}

//           {!paymentURL && (
//             <CheckoutPaymentMethods cardOptions={CARDS_OPTIONS} options={PAYMENT_OPTIONS} sx={{ my: 3 }} />
//           )}

//           {paymentURL && (
//             <Card>
//               <CardHeader title="Make Payment" />
//               <iframe src={paymentURL} title="Payment Frame" width="100%" height="500px" frameBorder="0" />
//             </Card>
//           )}
//           <Button
//             size="small"
//             color="inherit"
//             onClick={onBackStep}
//             startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
//           >
//             Back
//           </Button>
//         </Grid>

//         <Grid xs={12} md={4}>
//           <CheckoutBillingInfo onBackStep={onBackStep} billing={billing} />

//           <CheckoutSummary
//             enableEdit
//             total={total}
//             subTotal={subTotal}
//             discount={discount}
//             shipping={shipping}
//             onEdit={() => onGotoStep(0)}
//           />

//           <LoadingButton
//             fullWidth
//             color="primary"
//             size="large"
//             type="submit"
//             variant="contained"
//             loading={isSubmitting}
//           >
//             {payment === 'card' ? 'Make payment' : 'Complete Order'}
//           </LoadingButton>
//         </Grid>
//       </Grid>
//     </FormProvider>
//   );
// }

// CheckoutPayment.propTypes = {
//   onReset: PropTypes.func,
//   checkout: PropTypes.object,
//   onBackStep: PropTypes.func,
//   onGotoStep: PropTypes.func,
//   onNextStep: PropTypes.func,
//   onApplyShipping: PropTypes.func,
// };




// Updated code
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form';
//
import _ from 'lodash';
import { Card, CardHeader } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CheckoutSummary from './checkout-summary';
// import CheckoutDelivery from './checkout-delivery';
import CheckoutBillingInfo from './checkout-billing-info';
import CheckoutPaymentMethods from './checkout-payment-methods';
import axiosInstance from '../../../utils/axios';

// ----------------------------------------------------------------------

// const DELIVERY_OPTIONS = [
//   {
//     value: 0,
//     label: 'Free',
//     description: '5-7 Days delivery',
//   },
//   {
//     value: 10,
//     label: 'Standard',
//     description: '3-5 Days delivery',
//   },
//   {
//     value: 20,
//     label: 'Express',
//     description: '2-3 Days delivery',
//   },
// ];

const PAYMENT_OPTIONS = [
  {
    value: 'paypal',
    label: 'Pay with Paypal',
    description: 'You will be redirected to PayPal website to complete your purchase securely.',
  },
  {
    value: 'card',
    label: 'Credit / Debit Card',
    description: 'You will be redirected to payment page.',
  },
  {
    value: 'cash',
    label: 'Cash',
    description: 'Pay with cash when your order is delivered.',
  },
];

const CARDS_OPTIONS = [
  { value: 'ViSa1', label: '**** **** **** 1212 - Jimmy Holland' },
  { value: 'ViSa2', label: '**** **** **** 2424 - Shawn Stokes' },
  { value: 'MasterCard', label: '**** **** **** 4545 - Cole Armstrong' },
];

export default function CheckoutPayment({ checkout, onReset, onNextStep, onBackStep, onGotoStep, onApplyShipping, orderType }) {
  const { total, discount, subTotal, shipping, billing } = checkout;
  const [paymentURL] = useState('');

  const PaymentSchema = Yup.object().shape({
    payment: Yup.string().required('Payment is required!'),
  });

  const defaultValues = {
    delivery: shipping,
    payment: '',
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const payment_success = searchParams.get('payment_success');

    if (payment_success) {
      onNextStep();
      onReset();
    }
  }, [onNextStep, onReset, searchParams]);

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const { payment } = watch();

  const onSubmit = handleSubmit(async (data) => {
    console.log('data: ', data);
    try {
      const invoicesGrouped = _.groupBy(checkout.cart, 'brand_id');
      const invoices = [];
      const user = JSON.parse(localStorage.getItem('u') || '{}');

      if (!user && user?.user_id) {
        alert('Please login to continue');
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const key in invoicesGrouped) {
        if (Object.hasOwnProperty.call(invoicesGrouped, key)) {
          const itemArray = invoicesGrouped[key];
          const price = itemArray?.map((item) => item.price).reduce((prev, next) => prev + next);
          const locationInvoice = {
            brand_id: key,
            customer_id: itemArray[0].customer_id,
            location_id: itemArray[0].location_id,
            discount_id: null, 
            info_address: checkout.billing && checkout.billing.fullAddress ? checkout.billing.fullAddress : null,
            info_email: checkout.billing?.email || '', 
            info_name: checkout.billing?.name || '',  
            info_phone: `${user.country_code}${user.phone_number}`,
            is_paid: 0,
            items_number: itemArray?.length,
            order_type: 'Online',
            payable: price,
            payment_method: [
              {
                [data.payment]: price,
              },
            ],
            items: itemArray.map((i) => ({ ...i, product_id: i.id, qty: i.quantity })),
          };

          invoices.push(locationInvoice);
        }
      }

      const schema = {
        total: checkout.subTotal,
        vat: 0,
        subtotal: checkout.subTotal,
        note: 'Hey',
        discount_amount: checkout.discount,
        discount_pct: 0,
        customer_id: checkout.cart[0].customer_id,
        client_user_id: user?.user_id,
        user_address: orderType === 'Delivery' ? checkout?.billing?.fullAddress || "" : "N/A",
        user_phone: `${user.country_code}${user.phone_number}`,
        user_email: orderType === 'Delivery' ? checkout?.billing?.email || "" : "N/A",
        payment_method: data.payment,
        invoices,
      };

      if (data.payment === 'card') {
        schema.redirectURL = window.location.href;
      }

      const response = await axiosInstance.post('/api/v2/multi-location-orders/sync-order', schema);
      localStorage.setItem('rurl', `/dashboard/product${window.location.search}`);
      
      if (data.payment === 'cash') {
        onNextStep();
        onReset();
      }

      if (data.payment === 'card') {
        if (response?.data?.data?.payment_info?.paymentUrl) {
          window.open(`${response?.data?.data?.payment_info?.paymentUrl}`, '_top');
        }
      } else if (response?.data?.status) {
        if (data?.payment === 'cash') {
          onNextStep();
        }
        onReset();
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>

          {/* <CheckoutDelivery onApplyShipping={onApplyShipping} options={DELIVERY_OPTIONS} /> */}

          {!paymentURL && (
            <CheckoutPaymentMethods cardOptions={CARDS_OPTIONS} options={PAYMENT_OPTIONS} sx={{ my: 3 }} />
          )}

          {paymentURL && (
            <Card>
              <CardHeader title="Make Payment" />
              <iframe src={paymentURL} title="Payment Frame" width="100%" height="500px" frameBorder="0" />
            </Card>
          )}
          <Button
            size="small"
            color="inherit"
            onClick={onBackStep}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            Back
          </Button>
        </Grid>

        <Grid xs={12} md={4}>
          
          {orderType === 'Delivery' && (
            <CheckoutBillingInfo onBackStep={onBackStep} billing={billing} />
          )}

          <CheckoutSummary
            enableEdit
            total={total}
            subTotal={subTotal}
            discount={discount}
            shipping={shipping}
            onEdit={() => onGotoStep(0)}
          />

          <LoadingButton
            fullWidth
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {payment === 'card' ? 'Make payment' : 'Complete Order'}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

CheckoutPayment.propTypes = {
  onReset: PropTypes.func,
  checkout: PropTypes.object,
  onBackStep: PropTypes.func,
  onGotoStep: PropTypes.func,
  onNextStep: PropTypes.func,
  onApplyShipping: PropTypes.func,
  orderType: PropTypes.string.isRequired,
};
