// Old Code

// import { useEffect, useCallback } from 'react';
// // @mui
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Unstable_Grid2';
// import Typography from '@mui/material/Typography';
// // redux
// import { useDispatch } from 'src/redux/store';
// import { getCart } from 'src/redux/slices/checkout';
// // _mock
// import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
// // components
// import { useSettingsContext } from 'src/components/settings';
// //
// import UserNewEditForm from 'src/sections/user/user-new-edit-form';
// import { useCheckout } from '../../hooks';
// import CheckoutCart from '../checkout-cart';
// import CheckoutSteps from '../checkout-steps';
// import CheckoutPayment from '../checkout-payment';
// import CheckoutOrderComplete from '../checkout-order-complete';
// import CheckoutBillingAddress from '../checkout-billing-address';

// // ----------------------------------------------------------------------

// function useInitial(cart) {
//   const dispatch = useDispatch();

//   const getCartCallback = useCallback(() => {
//     if (cart.length) {
//       dispatch(getCart(cart));
//     }
//   }, [cart, dispatch]);

//   useEffect(() => {
//     getCartCallback();
//   }, [getCartCallback]);

//   return null;
// }

// // const activeStep = 3;
// export default function CheckoutView() {
//   const settings = useSettingsContext();

//   const {
//     checkout,
//     completed,
//     onResetAll,
//     onGotoStep,
//     onNextStep,
//     onBackStep,
//     onDeleteCart,
//     onResetBilling,
//     onCreateBilling,
//     onApplyDiscount,
//     onApplyShipping,
//     onIncreaseQuantity,
//     onDecreaseQuantity,
//   } = useCheckout();

//   const { cart, billing, activeStep } = checkout;

//   useInitial(cart);

//   useEffect(() => {
//     if (activeStep === 1) {
//       onResetBilling();
//     }
//   }, [activeStep,onResetBilling]);

//   return (
//     <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ mb: 10 }}>
//       <Typography variant="h4" sx={{ my: { xs: 3, md: 5 } }}>
//         Checkout
//       </Typography>

//       <Grid container justifyContent={completed ? 'center' : 'flex-start'}>
//         <Grid xs={12} md={8}>
//           <CheckoutSteps activeStep={activeStep} steps={PRODUCT_CHECKOUT_STEPS} />
//         </Grid>
//       </Grid>

//       {completed ? (
//         <CheckoutOrderComplete open={completed} onReset={onResetAll} onDownloadPDF={() => {}} />
//       ) : (
//         <>
//           {activeStep === 0 && (
//             <CheckoutCart
//               checkout={checkout}
//               onNextStep={onNextStep}
//               onDeleteCart={onDeleteCart}
//               onApplyDiscount={onApplyDiscount}
//               onIncreaseQuantity={onIncreaseQuantity}
//               onDecreaseQuantity={onDecreaseQuantity}
//             />
//           )}

//           {activeStep === 1 && <UserNewEditForm onNextStep={onNextStep} onBackStep={onBackStep} />}

//           {activeStep === 2 && (
//             <CheckoutBillingAddress checkout={checkout} onBackStep={onBackStep} onCreateBilling={onCreateBilling} />
//           )}

//           {activeStep === 3 && billing && (
//             <CheckoutPayment
//               checkout={checkout}
//               onNextStep={onNextStep}
//               onBackStep={onBackStep}
//               onGotoStep={onGotoStep}
//               onApplyShipping={onApplyShipping}
//               onReset={onResetAll}
//             />
//           )}
//         </>
//       )}
//     </Container>
//   );
// }



// Update code

import { useEffect, useCallback, useState } from 'react';
// @mui
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// redux
import { useDispatch } from 'src/redux/store';
import { getCart } from 'src/redux/slices/checkout';
// _mock
import { PRODUCT_CHECKOUT_STEPS } from 'src/_mock/_product';
// components
import { useSettingsContext } from 'src/components/settings';
//
import UserNewEditForm from 'src/sections/user/user-new-edit-form';
import { useCheckout } from '../../hooks';
import CheckoutCart from '../checkout-cart';
import CheckoutSteps from '../checkout-steps';
import CheckoutPayment from '../checkout-payment';
import CheckoutOrderComplete from '../checkout-order-complete';
import CheckoutBillingAddress from '../checkout-billing-address';

function useInitial(cart) {
  const dispatch = useDispatch();

  const getCartCallback = useCallback(() => {
    if (cart.length) {
      dispatch(getCart(cart));
    }
  }, [cart, dispatch]);

  useEffect(() => {
    getCartCallback();
  }, [getCartCallback]);

  return null;
}

export default function CheckoutView() {
  const settings = useSettingsContext();
  const [orderType, setOrderType] = useState('Eat-in');
  const [stepsToShow, setStepsToShow] = useState(PRODUCT_CHECKOUT_STEPS);

  const {
    checkout,
    completed,
    onResetAll,
    onGotoStep,
    onNextStep,
    onBackStep,
    onDeleteCart,
    onResetBilling,
    onCreateBilling,
    onApplyDiscount,
    onApplyShipping,
    onIncreaseQuantity,
    onDecreaseQuantity,
  } = useCheckout();

  const { cart, billing, activeStep } = checkout;

  useInitial(cart);

  useEffect(() => {
    if (activeStep === 1) {
      onResetBilling();
    }
  }, [activeStep, onResetBilling]);

  useEffect(() => {
    if (orderType === 'Delivery') {
      setStepsToShow(['Cart', 'Billing & address', 'Payment']);
    } else if (orderType === 'Eat-in' || orderType === 'Take-away') {
      setStepsToShow(['Cart', 'Payment']);
    }
  }, [orderType]);

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
    onGotoStep(0);
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ mb: 10 }}>
      <Typography variant="h4" sx={{ my: { xs: 3, md: 5 } }}>
        Checkout
      </Typography>
      <Grid container justifyContent="flex-start" sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="order-type-label" style={{ fontWeight: 'bold' }}>
              <strong>Select Order Type</strong>
            </InputLabel>
            <Select
              labelId="order-type-label"
              id="order-type-select"
              value={orderType}
              onChange={handleOrderTypeChange}
              label="Select Order Type"
              displayEmpty
            >
              <MenuItem value="Delivery">Delivery</MenuItem>
              <MenuItem value="Eat-in">Eat-in</MenuItem>
              <MenuItem value="Take-away">Take-away</MenuItem>
            </Select>
          </FormControl>

          {orderType && (
            <div style={{ marginTop: '10px' }}>
              <strong>Selected Order Type: </strong>
              {orderType}
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent={completed ? 'center' : 'flex-start'}>
        <Grid xs={12} md={8}>
          <CheckoutSteps activeStep={activeStep} steps={stepsToShow} />
        </Grid>
      </Grid>

     <UserNewEditForm onNextStep={onNextStep} onBackStep={onBackStep} />

      {completed ? (
        <CheckoutOrderComplete open={completed} onReset={onResetAll} onDownloadPDF={() => {}} />
      ) : (
        <>
          {activeStep === 0 && (
            <CheckoutCart
              checkout={checkout}
              onNextStep={onNextStep}
              onDeleteCart={onDeleteCart}
              onApplyDiscount={onApplyDiscount}
              onIncreaseQuantity={onIncreaseQuantity}
              onDecreaseQuantity={onDecreaseQuantity}
            />
          )}

          {orderType === 'Delivery' && activeStep === 1 && (
            <CheckoutBillingAddress
              checkout={checkout}
              onBackStep={onBackStep}
              onNextStep={onNextStep}
              onCreateBilling={onCreateBilling}
            />
          )}

          {(orderType === 'Delivery' && activeStep === 2  && billing) && (
            <CheckoutPayment
              checkout={checkout}
              onNextStep={onNextStep}
              onBackStep={onBackStep}
              onGotoStep={onGotoStep}
              onApplyShipping={onApplyShipping}
              onReset={onResetAll}
              orderType={orderType} 
            />
          )}

          {((orderType === 'Eat-in' || orderType === 'Take-away') && activeStep === 1) && (
            <CheckoutPayment
              checkout={checkout}
              onNextStep={onNextStep}
              onBackStep={onBackStep}
              onGotoStep={onGotoStep}
              onApplyShipping={onApplyShipping}
              onReset={onResetAll}
              orderType={orderType} 
            />
          )}
        </>
      )}
    </Container>
  );
}

